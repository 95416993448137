.error__alert {
  color: #FFFFFF;
  padding: 2rem 1rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 0.25rem;
  width: 20rem;

}
.error__alert--button {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
}
.error__alert--button button {
  color: #FFFFFF;
  border-radius: 0.4rem;
  padding: 0.25rem 1rem;
  outline: none;
  border: none;
  cursor: pointer;
  transition: var(--speed);
}
.error__alert--button button:hover {
  opacity: 0.7;
}