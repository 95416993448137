.loading {
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding: 2rem;
  gap: 0.5rem;
  height: 100dvh;
  width: 100dvw;
}
.loading__left {
  display: grid;
  grid-template-rows: 1fr 1fr;
  gap: 0.5rem;
}
.loading__left__top {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  gap: 0.5rem;
}
.loading__right {
  display: grid;
  grid-template-rows: 1.5fr 1fr;
  gap: 0.5rem;
}
.loading__right__bottom {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0.5rem;
}

@keyframes ripple {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.99);
    opacity: 1;
  }
  100% {
    transform: scale(1.001);
    opacity: 0.8;
  }
}
@keyframes wave {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 0;
  }
}

/* Apply the animation to your loading containers */
.dummy__container {
  width: 100%;
  height: 100%; /* Adjust the container height as needed */
  background: linear-gradient(90deg, whitesmoke 25%, #ddd 50%, #ccc 75%);
  background-size: 200% 100%;
  animation: wave 2s linear infinite; /* Adjust animation duration as needed */
  min-height: 5rem;
}

.dummy__container {
  background-color: whitesmoke;
  border-radius: 0.5rem;
  animation: ripple 1.5s infinite; /* Adjust animation duration as needed */
  transform-origin: center;
}
@media screen and (max-width: 1000px) {
  .loading {
    padding: 0.5rem;
    height: unset;
  }
  .loading, .loading__right__bottom {
    display: flex;
    flex-direction: column;
  }
}