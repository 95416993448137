.disabled {
  opacity: 0.5;
}

.app__home--display {
  flex: 8;
  transition: left 1s ease;
  position: relative;
  height: 100dvh;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  width: calc(100vw - 20rem);
}
.main__padding {
  padding: 1rem;
}
.app__main {
  height: calc(100dvh - 4.5rem);
  overflow: scroll;
}


@media screen and (max-width: 1000px) {
  .main__padding {
    padding: 1rem 1rem 0 1rem;
  }
  .app__main {
    height: unset;
    overflow: unset;
    min-height: 100dvh;
  }
}



.shake {
  animation: shake 0.2s ease-in-out;
}

@keyframes shake {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(-5px, 0);
  }
  50% {
    transform: translate(0);
  }
  75% {
    transform: translate(5px, 0);
  }
  100% {
    transform: translate(0);
  }
}

.scale-up-center {
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}


@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@media screen and (max-width: 700px) {
  .section__padding {
      padding: 4rem;
  }
  .section__margin {
      padding: 4rem;
  }
}

@media screen and (max-width: 550px) {
  .section__padding {
      padding: 4rem 2rem;
  }
  .section__margin {
      padding: 4rem 2rem;
  }
}

.top__bar__row {
  width: 100%;
  border-bottom: 2px solid var(--color-mode-secondary);
  background-color: rgba(9, 83, 115, 0.082);
  height: 2.5rem;
  min-height: 2.5rem;
  padding: 0.125rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;

}
.top__bar__row span {
  width: 2.25rem;
  height: 2.25rem;
  background-color: rgba(9, 83, 115, 0.082);
  border-radius: 0.3rem;
}




.bottom__bar {
  height: 2.5rem;
  position: fixed;
  top: 0;
  z-index: 100;
  display: flex;
  font-size: 2rem;
  align-items: center;
  padding: 0 0.5rem;
}
.bottom__bar div {
  display: flex;
  gap: 0.5rem;
}
.open__closed {
  display: none;
}

@media screen and (max-width: 1000px) {
  .app__main {
    height: unset;
    overflow: unset;
    margin-top: 2.5rem;
  }
  .options__div {
    position: fixed;
    bottom: 0.5rem;
    right: 0.5rem;
  }
  .open__closed {
    color: #0156ab;
    z-index: 20;
    font-size: 1.4rem;
    padding: 0.5rem 0.125rem;
    display: flex;
    align-items: center;
    justify-content: center;
  } 
  .top__bar__row {
    position: fixed;
    top: 0;
    z-index: 2;
  }
}
.page {
  height: 100dvh;
  display: flex;
  flex-direction: column;
  position: relative;
}
@media screen and (max-width:1000px) {

  .page {
    height: unset;
  }
}
.siteHub__app--home {
  width: 100vw;
  height: 100% ;
  display: flex;
}
.top__info {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.user__dot {
  display: flex;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  border: 3px solid var(--clr-grey-2);
}
.user__dot.clocked__out {
  background-color: rgba(255, 0, 0, 0.572);
}

.user__dot.clocked__in {
  background-color: rgba(5, 238, 5, 0.34);
}


@media screen and (max-width: 700px) {
  .siteHub__app--home {
    width: 100vw;
    /* height: fit-content; */
    display: flex;
    min-height: 100dvh;
  }
  
  .app__home--display {
    flex: 8;
    transition: left 1s ease;
    position: relative;
    height: unset;
    margin: 0.5rem 0;
  }
}

.use__dash__container {
  border-radius: 0.4rem;
  background-color: var(--color-mode-secondary);
}





