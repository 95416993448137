.new__company {
  height: 100%;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('./Pages/SignIn/projectSiteView.png');
  position: relative;
  height: 100vh;
}
.new__company__bg {
  width: 100%;
  height: 100%;
  backdrop-filter: blur(30px);
  position: absolute;
}
.new__company--info {
  width: 40rem;
  height: 10rem;
  flex-direction: column;

}
.new__head {
  display: flex;
  text-align: center;
  gap: 0.5rem;
  color: #FFFFFF;
  flex-direction: column;
  padding: 0.5rem;
}
.company__name--input {
  display: flex;
  padding: 1rem;
  gap: 0.1rem;
}
.company__name--input input {
  flex: 8;
  border: 3px solid var(--color-footer);
  color: #FFFFFF;
}
.company__name--input span { 
  flex: 1;
  border: 3px solid var(--color-footer);
  color: #FFFFFF;
}
.no__email {
  width: 100%;
  color: #FFFFFF;
}
.no__email span {
  text-decoration: underline;
  opacity: 0.7;
}


.details__container {
  background-color: whitesmoke;
  padding: 2rem;
  min-width: 40rem;
  border-radius: 1rem;
  padding: 4rem;
  height: fit-content;
  text-align: center;
  z-index: 3;
}

.details__container h2 {
  font-size: 2.5rem;
}

.name__img {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
  align-items: center;
}
.custom-image-upload input {
  display: none;
}
.details__container input, .details__container select {
  padding-left: 1rem;
  background-color: #FFF !important;
  border-radius: 0.4rem;
}
.name__img label span {
  font-size: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom-image-upload {
  width: 100%;
  height: 100%;

  background: radial-gradient(circle at 128% -70%, blue 35%, rgb(114, 161, 248) 85%, rgb(14, 56, 64) 10%);
  background-size: 190% 100%;
  border-radius: 1rem !important;
  position: relative;
  cursor: pointer;
  height: 10rem;
  margin: 2rem 0;
}
.custom-image-upload h2 {
  color: #FFF !important;
}
.custom__image__over__lay {
  border-radius: 1rem !important;
  backdrop-filter: blur(93px);
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
@media screen and (max-width:41rem) {
  .details__container {
    width: 98vw;
    min-width: unset;
    padding: 1rem 0.5rem;
  }
}