.error-page {
    height: 100dvh;
    width: 100dvw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: rgb(251, 249, 249);
}
.error-logo {
    font-size: 4rem;
    padding: 2rem;
}
.error-page h1{
    color: var(--color-blue);
}
.error-msg  {
    color: rgb(132, 127, 127);
}
.home-link {
    color: rgb(132, 127, 127);
    text-decoration: underline;
    padding: 2rem;
    cursor: pointer;
    transition: var(--speed);
}
.home-link:hover {
    opacity: 0.75;
}
@media screen and (max-width:1000px) {
    .error-logo {
        font-size: 2.2rem;
        padding: 1rem;
    }
}