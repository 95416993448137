input {
  background-color: var(--color-mode-secondary);
  width: 100%;
}
button {
  background-color: #0157ad;
  border-radius: 0.5rem;
  padding: 0.25rem .5rem;
  color: #FFF;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.success__box {
  background-color: rgb(3, 201, 3);
  color: #FFF;
  padding: 0.5rem 1rem;
  border-radius: 0.2rem;
}
.doc__viewer {
  width: 100%;
  min-height: 100%;
}
.doc__viewer iframe {
  min-height: 100% !important;
}
.view__image {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

}
.view__image div {
  backdrop-filter: blur(8000px);
  position: absolute;
  top: 0.2rem;
  right: 0.2rem;
  display: flex;
  gap: 0.5rem;
}
.view__image img {
  max-width: 90%;
  max-height: 90%;
}
.signature {
  display: flex;
  gap: 0.75rem;
  width: 15rem;
  flex-direction: column;
  padding: 1rem;
  background-color: var(--color-mode-main) !important;
}
.signature canvas {
  width: 100%;
  height: 100%;
  border-radius: 0.3rem;
  background-color: var(--color-mode-secondary);
  color: var(--color-text);
}
